import React from "react"

import Layout from "../../../components/Layout"
import { graphql, Link } from "gatsby"
import Card from "../../../components/ui/Card"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import TextLink from "../../../components/ui/TextLink"
import Seo from "../../../components/Seo"

export default function Blog({ data }) {
  const blogs = data.allStrapiBlogs.nodes

  return (
    <Layout>
      <Seo title="Blogs" />
      <section className="wrapper container py-8 sm:py-12 md:py-16">
        <h1 className="mb-4 sm:mb-8">Blog Articles</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 auto-rows-fr gap-4 sm:gap-8">
          {blogs.map(blog => (
            <Link to={"/media/blog/" + blog.slug} className="group">
              <Card className="bg-secondary-light/25 duration-500 h-full group-hover:bg-secondary-light/50 p-4">
                <div className="h-full">
                  <div className="h-1/2 overflow-hidden mb-2">
                    {blog.image.localFile ? (
                      <GatsbyImage
                        className="h-full w-full transform transition-transform duration-500 group-hover:scale-125"
                        // alt={}
                        image={getImage(blog.image.localFile)}
                      />
                    ) : (
                      <StaticImage
                        src="../../../images/find-a-builder.jpeg"
                        className="h-full w-full transform transition-transform duration-500 group-hover:scale-125"
                      />
                    )}
                  </div>
                  <div className="h-1/2 flex flex-col">
                    <div className="space-y-2">
                      <h4>{blog.title}</h4>
                      <p>{blog.publishedAt}</p>
                      <p>{getExcerpt(blog.excerpt)}</p>
                    </div>
                    <TextLink className="mt-auto">Read more</TextLink>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

function getExcerpt(string, maxExcerptLength = 120) {
  const excerpt = string.slice(0, maxExcerptLength)

  if (string.length > maxExcerptLength) {
    return excerpt + "..."
  }

  return excerpt
}

export const query = graphql`
  query allBlogsQuery {
    allStrapiBlogs {
      nodes {
        slug
        title
        excerpt
        publishedAt: published_at(formatString: "Do MMMM, YYYY")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
